$(window).ready(function () {

    $('.anchor-link').click(function(e){
        e.preventDefault();
        var target = $($(this).attr('href'));
        if(target.length){
            var scrollTo = target.offset().top;
            $('body, html').animate({scrollTop: scrollTo+'px'}, 800);
        }
    });

    $(".specialist__name").html(function(){
        var text= $(this).text().trim().split(" ");
        var first = text.shift();
        return (text.length > 0 ? "<span class='first_word'>"+ first + "</span> " : first) + text.join(" ");
    });


    $(function() {
        var header = $(".top__nav");
        var btn = $("#button__top");

        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            if (scroll >= 300) {
                header.addClass("scrolled");
                btn.addClass('show');
            } else {
                header.removeClass("scrolled");
                btn.removeClass('show');
            }
        });
    });

    $("#button__top").on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '300');
    });

    $(".sub_dr").click(function(){
        $(".sub-menu").slideToggle();
    });

    $("button.burger").click(function(){
        $(this).toggleClass("active");
        $('.menu__top').toggleClass("active");
        $('body').toggleClass("over_hide");
    });

    $(".top__nav a.anchor-link").click(function(){
        $("button.burger").removeClass("active");
        $('.menu__top').removeClass("active");
        $('body').removeClass("over_hide");
    });


    $('.articles__home__block').slick({
        dots: true,
        swipeToSlide: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: $('.a-prev-button-slick'),
        nextArrow: $('.a-next-button-slick'),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: false
                }
            }
        ]
    });

    $('.gallery__home__block').slick({
        dots: true,
        infinite: false,
        swipeToSlide: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: $('.g-prev-button-slick'),
        nextArrow: $('.g-next-button-slick'),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: false
                }
            }
        ]
    });

    $('.certificate__slider').slick({
        dots: false,
        infinite: false,
        swipeToSlide: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: $('.slide-prev'),
        nextArrow: $('.slide-next'),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: false
                }
            }
        ]
    });

    $('#btn_submit_question').click(function(){
        // собираем данные с формы
        var user_name_question 	    = $('#user_name_question').val();
        var user_email_question 	    = $('#user_email_question').val();
        var text_comment_question    = $('#text_comment_question').val();
        // отправляем данные
        $.ajax({
            url: "/form/question.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_question": 	user_name_question,
                "user_email_question": user_email_question,
                "text_comment_question": text_comment_question
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-question').html(data.result); // выводим ответ сервера
            }
        });
    });

});



